import React from "react";
import "./styles/App.css";
import "./styles/responsive.css";
import "./styles/fonts.css";
import { Header } from "./components/Header";
import { ReqDocuments } from "./components/ReqDocuments";
import { ScopeOfAccreditation } from "./components/ScopeOfAccreditation";
import { MapYandex } from "./components/MapYandex";
import { Accreditation } from "./components/Accreditation";
import { Footer } from "./components/Footer";
import { ScrollTop } from "./components/ScrollTop";
import { useScrollToTop } from "./components/Hooks/useScrollToTop";
import { useOpenBurgerMenu } from "./components/Hooks/useOpenBurgerMenu";
import { BurgerMenu } from "./components/BurgerMenu";
import { Features } from "./components/Features";

function App() {
  const scrollToTop = useScrollToTop();
  const openBurgerMenu = useOpenBurgerMenu();

  return (
    <div className="App">
      <Header { ...openBurgerMenu } />
      <ReqDocuments />
      <ScopeOfAccreditation />
      <Features />
      <MapYandex />
      <Accreditation />
      <Footer />
      <ScrollTop { ...scrollToTop } />
      <BurgerMenu { ...openBurgerMenu } />
    </div>
  );
}

export default App;

import motoIcon from "../images/icons/moto.svg";
import carIcon from "../images/icons/car.svg";
import truckIcon from "../images/icons/truck.svg";
import trailerIcon from "../images/icons/trailer.svg";
import doc1 from "../images/files/doc1.webp";
import doc2 from "../images/files/doc2.webp";

export const information = {
  inn: "525610085089",
  ogrn: "322527500063547",
  numberRSA: "13866",
  name: "Богатырь Кирилл Александрович",
  address: "Московское шоссе, 302а",
  fullAddress: "603092, Нижегородская область, г. Нижний Новгород, Московское шоссе, 302а",
  tel1: "+7 (831) 234-35-05",
  tel2: "+7 (910) 896-55-05",
  hrefTel1: "tel:+78312343505",
  hrefTel2: "tel:+79108965505",
  email: "sto-bogatyr@mail.ru",
  hrefEmail: "mailto:sto-bogatyr@mail.ru",
};

export const links = [
  {
    id: 1,
    text: "Область аккредитации",
    href: "#categories",
  },
  {
    id: 2,
    text: "Адрес ПТО",
    href: "#address-pto",
  },
  {
    id: 3,
    text: "Контакты",
    href: "#contacts",
  },
  {
    id: 4,
    text: "Аттестат",
    href: "#certificate",
  },
  {
    id: 5,
    text: "ЭПТС и СБКТС",
    href: "#epts"
  }
];

export const footerLinks = [
  {
    id: 1,
    text: "Необходимые документы",
    href: "#documents",
  },
  {
    id: 2,
    text: "Область аккредитации",
    href: "#categories",
  },
  {
    id: 3,
    text: "Контакты",
    href: "#address-pto",
  },
  {
    id: 4,
    text: "Аттестат",
    href: "#certificate",
  },
  {
    id: 5,
    text: "ЭПТС и СБКТС",
    href: "#epts"
  }
];

export const categories = [
  {
    id: 1,
    image: (
      <img
        src={ motoIcon }
        className="vehicle-categories__icons"
        alt="moto-icon"
      />
    ),
    header: "L",
    text: "Мопеды, мотовелосипеды, мокики, мотоциклы, мотороллеры, трициклы, квадрициклы.",
  },
  {
    id: 2,
    image: (
      <img src={ carIcon } className="vehicle-categories__icons" alt="car-icon" />
    ),
    header: "M1",
    text: "Транспортные средства, используемые для перевозки пассажиров и имеющие,помимо места водителя,не более восьми мест для сидения",
  },
  {
    id: 3,
    image: (
      <img
        src={ truckIcon }
        className="vehicle-categories__icons"
        alt="truck-icon"
      />
    ),
    header: "N1",
    text: "Транспортные средства, предназначенные для перевозки грузов, имеющие технически допустимую максимальную массу не более 3,5 тонн.",
  },
  {
    id: 4,
    image: (
      <img
        src={ trailerIcon }
        className="vehicle-categories__icons"
        alt="trailer-icon"
      />
    ),
    header: "O1",
    text: "Прицепы, технически допустимая максимальная масса которых не более 0,75 тонн.",
  },
  {
    id: 5,
    image: (
      <img
        src={ trailerIcon }
        className="vehicle-categories__icons"
        alt="trailer-icon"
      />
    ),
    header: "O2",
    text: "Прицепы, технически допустимая максимальная масса которых свыше 0,75 т, но не более 3,5 тонн.",
  },
];

export const features = [
  {
    id: 1,
    header: "Оформление ЭПТС и СБКТС",
    text: "Оформление Электронного паспорта транспортного средства и Свидетельства о безопасности конструкции" +
      " транспортного средства.",
    contact: "Кирилл Архипов:",
    tel: "+7 (920) 009-84-59",
    hrefTel: "tel:+79200098459"
  },
];

export const documents = [
  {
    id: 1,
    image: <img src={ doc1 } alt="doc1" />,
  },
  {
    id: 2,
    image: <img src={ doc2 } alt="doc2" />,
  },
];
